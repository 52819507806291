import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"

import {
  Jumbotron,
  Container,
  Media,
  Row,
  Col,
  Card,
  Figure,
} from "react-bootstrap"

import CocinasImage from "../images/reigas_imgs_1_cocinas.png"
import PerneriaImage from "../images/reigas_imgs_1_Pernos.png"
import TuberiaFerreteriaImage from "../images/reigas_imgs_ferrre.png"

import ReguladorImage from "../images/regulador_prod.png"
import CocinaImage from "../images/Cocina-prod.png"
import HornosImage from "../images/horno-prod.png"
import PernosImage from "../images/pernos-prod.png"

const products = [
  {
    name: "Reguladores y Cocinas Industriales",
    description:
      "Ofrecemos: Reguladores, cocinas, freidoras, hornos, batidoras. (Todo para su negocio)",
    image: CocinasImage,
    to: "/cocinas",
  },
  {
    name: "Pernería",
    description:
      "Ofrecemos un surtido completo de: pernos, tuercas, tornillos, arandelas y más.",
    image: PerneriaImage,
    to: "/pernos",
  },
  {
    name: "Tuberías & Ferretería en general",
    description:
      "Tuberías, herramientas manuales, accesorios de riego y material ferretero en general.",
    image: TuberiaFerreteriaImage,
    to: "/material-ferretero",
  },
]
const starProducts = [
  {
    name: "Reguladores de gas - Reigas",
    image: ReguladorImage,
  },
  {
    name: "Cocinas industriales",
    image: CocinaImage,
  },
  {
    name: "Hornos industriales",
    image: HornosImage,
  },
  {
    name: "Pernos",
    image: PernosImage,
  },
]

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Jumbotron fluid>
      <Container>
        <h1>Importador Reigas</h1>
        <p>
          Garantía, Calidad y servicio en nuestra marca, en los productos de
          ferretería, pernería en general, reguladores para gas y equipamiento
          industrial para hoteles, bares y restaurantes.
        </p>
      </Container>
    </Jumbotron>
    <Row>
      <Col md="8" className="categories">
        {showProducts(products)}
      </Col>
      <Col>{showStartProducts(starProducts)}</Col>
    </Row>
  </Layout>
)

const showProducts = products => {
  const productsToRender = products.map(product => (
    <div className="productItem">
      <Link to={product.to}>
        <Media key={product.to}>
          <img
            // width={150}
            height={150}
            className="mr-3"
            src={product.image}
            alt={product.name}
          />
          <Media.Body>
            <h2>{product.name}</h2>
            <p>{product.description}</p>
          </Media.Body>
        </Media>
      </Link>
      <hr />
    </div>
  ))

  return productsToRender
}

const showStartProducts = products => {
  const featuredProducts = products.map(product => {
    return (
      <Figure key={product.name}>
        <Figure.Image alt={product.name} src={product.image} />
        <Figure.Caption className="text-center">{product.name}</Figure.Caption>
      </Figure>
    )
  })

  const featuredProductsCard = (
    <Card>
      <Card.Header>Productos Lider</Card.Header>
      <Card.Body>{featuredProducts}</Card.Body>
    </Card>
  )

  return featuredProductsCard
}

export default IndexPage
